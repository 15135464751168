//#region react import
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
//#endregion

//#region component import
import AcreosNavigation from "../../Components/AcreosNavigation/AcreosNavigation.component";
import BackButton from "../../Components/BackButton/BackButton.component";
import ExportPdfOptions from "../../Components/ExportPdfOptions/ExportPdfOptions.component";
import ExportPdfParametersModal from "../../Components/ExportPdfParametersModal/ExportPdfParametersModal.component";
import GstaLoaderPage from "../../Components/GstaLoaderPage/GstaLoaderPage.component";
import SwitchWithText from "../../Components/SwitchWithText/SwitchWithText.component";
import GlobalLayout from "../../Layout/GlobalLayout/GlobalLayout.component";
import AddTrainingModal from "../../Pages/Training/AddTrainingModal/AddTrainingModal.component";
//#endregion

//#region store import
import { setErrorNotification, setSuccessNotification } from "../../ReduxStore/notificationSlice";
import { setStudent } from "../../ReduxStore/studentsSlice";
//#endregion

//#region function import
import ScrollToTopController from "../../Helper/CustomHook/ScrollToTopController";
import useClickOutside from "../../Helper/CustomHook/useClickOutside";
import useNotationType from "../../Helper/CustomHook/useNotationType";
import { PrepareCustomPdf, getNotificationMessage } from "../../Helper/ExportPdf";
import { DisableOverflow, EnableOverflow } from "../../Helper/Overflow";
import { getEndPeriod, getStartPeriod } from "../../Pages/Student/StudentPage/StudentPage.constants";
import { getStudentResultsByTemplate } from "../../Services/StudentService";
//#endregion

//#region service import
import { getTranslations } from "../../Helper/TranslationController";
import { setSimulatorAccess } from "../../Services/StudentService";
import {
  closeStudentSubscription,
  createStudentSubscription,
  getStudentSubscriptions,
  studentResubscription,
} from "../../Services/SubscriptionsService";
//#endregion

//#region constants import
import { NO, YES } from "../../Components/GstaModal/GstaModal.constants";
import { STUDENT_INSCRIPTION } from "../../Constants/AddtrainingModalType";
import { MANAGE_STUDENTS_ACCESS_WITH_DATES } from "../../Constants/SettingsConstants";
import { STUDENTS, STUDENTS_PERSONNAL_INFORMATIONS, STUDENTS_TRAINING } from "../../Routing/PageNames";
//#endregion

//#region style import
import "./StudentDetailsGlobalLayout.scss";
//#endregion

const StudentDetailsGlobalLayout = ({ children }) => {
  //#region usestate
  const { state } = useLocation();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedTrainingToSubscribe, setSelectedTrainingToSubscribe] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [trainingsToUnsubscribe, setTrainingsToUnsubscribe] = useState([]);
  const [trainingsToResubscribe, setTrainingsToResubscribe] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showExportPdfOptions, setShowExportPdfOptions] = useState(false);
  const [modalExportOpen, setModalExportOpen] = useState(false);
  const [pdfTemplates, setPdfTemplates] = useState(state?.exportPdfData.pdfTemplates);
  const [selectedPdfTemplate, setSelectedPdfTemplate] = useState(state?.exportPdfData.selectedPdfTemplate);
  const [selectedPeriod, setSelectedPeriod] = useState(state?.exportPdfData.selectedPeriod);
  const [personalizedStartDate, setPersonalizedStartDate] = useState();
  const [personalizedEndDate, setPersonalizedEndDate] = useState();
  //#endregion

  //#region other use
  const dispatch = useDispatch();
  const notationType = useSelector((state) => state.settingSlice.notationType);
  const student = useSelector((state) => state.studentSlice.student);
  const customFields = useSelector((state) => state.studentSlice.customFields);
  const centerId = useSelector((state) => state.connexionSlice.trainer?.activeCenter?.id);
  const centerName = useSelector((state) => state.connexionSlice.trainer?.activeCenter?.name);
  const translations = useSelector((state) => state.translationSlice.translations);
  const sessionDates = useSelector((state) => state.settingSlice.settingDictionary[MANAGE_STUDENTS_ACCESS_WITH_DATES]);
  //#endregion

  //#region constant
  const dataStudentDetailMenu = [
    {
      id: 1,
      url: STUDENTS_TRAINING.url,
      title: getTranslations(STUDENTS_TRAINING.pageTraductionKey, translations),
    },
    {
      id: 2,
      url: STUDENTS_PERSONNAL_INFORMATIONS.url,
      title: getTranslations(STUDENTS_PERSONNAL_INFORMATIONS.pageTraductionKey, translations),
    },
  ];
  //#endregion

  //#region functions import
  const handleOpenModal = async () => {
    try {
      setModalOpen(!modalOpen);
      DisableOverflow();
      if (!modalOpen) {
        setSelectedTrainingToSubscribe([]);
        setTrainingsToUnsubscribe([]);
        setTrainingsToResubscribe([]);
        const studentSubscriptions = await getStudentSubscriptions(centerId, student.id);
        setSubscriptions(studentSubscriptions);
      }
    } catch (e) {
      EnableOverflow();
      dispatch(setErrorNotification(e));
    }
  };

  const handleCloseTrainingModal = () => {
    setModalOpen(false);
    EnableOverflow();
  };

  const handleValidate = async () => {
    try {
      await createStudentSubscription(
        centerId,
        selectedTrainingToSubscribe.map((trainingId) => ({
          studentId: student.id,
          trainingId: trainingId,
        }))
      );

      if (trainingsToUnsubscribe.length > 0) {
        await closeStudentSubscription(centerId, trainingsToUnsubscribe);
      }

      if (trainingsToResubscribe.length > 0) {
        await studentResubscription(centerId, trainingsToResubscribe);
      }
      dispatch(setSuccessNotification(getTranslations("notification_student_inscription", translations)));
    } catch (e) {
      dispatch(setErrorNotification(e));
    }
  };

  const handleChangeStudentSimulatorAccess = async () => {
    try {
      const newValue = (await setSimulatorAccess(centerId, student.id, !student.isActive)).dataModified;
      const studentModified = { ...student };
      studentModified.isActive = newValue;
      dispatch(setStudent({ newStudent: studentModified }));
    } catch (e) {
      dispatch(setErrorNotification(e));
    }
  };

  const generateCustomPdfDocument = async () => {
    setLoading(true);
    let personalizedStartDateCloned;
    let personalizedEndDateCloned;
    if (!modalExportOpen) {
      personalizedStartDateCloned = null;
      personalizedEndDateCloned = null;
    } else {
      personalizedStartDateCloned = personalizedStartDate;
      personalizedEndDateCloned = personalizedEndDate;
    }
    try {
      const studentResult = await getStudentResultsByTemplate(centerId, student.id, {
        templateId: state.exportPdfData.selectedPdfTemplate.id,
        startPeriod: getStartPeriod(
          student,
          sessionDates,
          state.exportPdfData.selectedPeriod,
          personalizedStartDateCloned
        ),
        endPeriod: getEndPeriod(student, sessionDates, state.exportPdfData.selectedPeriod, personalizedEndDateCloned),
      });
      await PrepareCustomPdf(
        notationType,
        translations,
        getStartPeriod(student, sessionDates, state.exportPdfData.selectedPeriod, personalizedStartDateCloned),
        getEndPeriod(student, sessionDates, state.exportPdfData.selectedPeriod, personalizedEndDateCloned),
        sessionDates,
        customFields,
        state.exportPdfData.selectedPdfTemplate,
        {
          ...studentResult,
          customValues: student.customValues,
          firstName: student.firstName,
          lastName: student.name,
          center: centerName,
        },
        centerId
      );
      dispatch(setSuccessNotification(getNotificationMessage(student, translations)));
    } catch (e) {
      dispatch(setErrorNotification(e));
    }
    setLoading(false);
    setShowExportPdfOptions(false);
    setModalExportOpen(false);
  };
  //#endregion

  //#region useQuery
  //#endregion

  //#region hook
  useNotationType(dispatch, centerId, notationType);
  const refExportPdfOptions = useRef();
  useClickOutside([refExportPdfOptions], true, setShowExportPdfOptions);
  //#endregion

  return (
    <GlobalLayout
      icon="helmet"
      title={STUDENTS.pageTraductionKey}
    >
      <main className="student-details-global-layout">
        {loading && <GstaLoaderPage />}
        <ScrollToTopController />
        <div>
          <div className="header-student-detail-back-button">
            <BackButton text={getTranslations("student_detail_back_student_list", translations)} />
          </div>
          <div className="header-student-detail-and-button">
            <div className="header-student-detail">
              <h1 className="header-student-detail-name">
                {student.name} {student.firstName}
              </h1>
              {!(sessionDates === "true") && (
                <div className="header-student-detail-simulator-access">
                  <span className="header-student-detail-simulator-access-span">
                    {getTranslations("student_detail_simulator_access", translations)}
                  </span>
                  <SwitchWithText
                    dataTestId={"switch-simulator-access"}
                    onText={getTranslations(YES, translations)}
                    offText={getTranslations(NO, translations)}
                    checked={student.isActive}
                    handleChange={handleChangeStudentSimulatorAccess}
                  />
                </div>
              )}
            </div>
            <div className="header-student-detail-button">
              <button
                className="gsta-button-outlined"
                data-testid="student-detail-button-export-test-id"
                onClick={() => setShowExportPdfOptions(!showExportPdfOptions)}
                disabled={loading}
              >
                <i className="icon-pdf" />
                <span className="header-student-detail-button-export-label">
                  {getTranslations("student_detail_result_export", translations)}
                </span>
              </button>
              <button
                className="gsta-button-outlined"
                onClick={handleOpenModal}
                data-testid="student-detail-button-gestion-test-id"
              >
                <i className="icon-note_add" />
                <span>{getTranslations("student_detail_inscription_manage", translations)}</span>
              </button>
              {showExportPdfOptions && (
                <ExportPdfOptions
                  refExportPdfOptions={refExportPdfOptions}
                  setModalOpen={setModalExportOpen}
                  generateCustomPdfDocument={generateCustomPdfDocument}
                />
              )}
              <ExportPdfParametersModal
                modalOpen={modalExportOpen}
                setModalOpen={setModalExportOpen}
                generateCustomPdfDocument={generateCustomPdfDocument}
                pdfTemplates={pdfTemplates}
                setPdfTemplates={setPdfTemplates}
                selectedPeriod={selectedPeriod}
                setSelectedPeriod={setSelectedPeriod}
                selectedPdfTemplate={selectedPdfTemplate}
                setSelectedPdfTemplate={setSelectedPdfTemplate}
                personalizedStartDate={personalizedStartDate}
                setPersonalizedStartDate={setPersonalizedStartDate}
                personalizedEndDate={personalizedEndDate}
                setPersonalizedEndDate={setPersonalizedEndDate}
              />
            </div>
          </div>
          <div className="margin-left-right">
            <AcreosNavigation
              maxNavElement={6}
              navData={dataStudentDetailMenu}
              structureClass={"gsta-sub-navigation"}
              overrideCustomLinkClass={"gsta-sub-navigation-link"}
            />
          </div>
        </div>
        {modalOpen && (
          <AddTrainingModal
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            handleModalClose={handleCloseTrainingModal}
            selectedTrainingToSubscribe={selectedTrainingToSubscribe}
            setSelectedTrainingToSubscribe={setSelectedTrainingToSubscribe}
            selectedTrainingToReSubscribe={trainingsToResubscribe}
            setSelectedTrainingToReSubscribe={setTrainingsToResubscribe}
            selectedTrainingToUnsubscribe={trainingsToUnsubscribe}
            setSelectedTrainingToUnsubscribe={setTrainingsToUnsubscribe}
            subscriptions={subscriptions}
            setTrainingSubscribed={setSubscriptions}
            setValues={handleValidate}
            type={STUDENT_INSCRIPTION}
            students={[student]}
            title={`${getTranslations("add_training_modal_student_training_inscription", translations)} ${
              student.firstName
            } ${student.name}`}
          />
        )}
        <div className="margin-left-right">{children}</div>
      </main>
    </GlobalLayout>
  );
};

export default StudentDetailsGlobalLayout;
