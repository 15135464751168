//#region react import
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
//#endregion

//#region component import
import GroupedPdf from "../Components/GroupedPdf/GroupedPdf.component";
import CustomPdf from "../Components/Pdf/CustomPdf/CustomPdf.component";
//#endregion

//#region service import
import { GetPdfInfos } from "../Services/SettingsService";
import { GetResultsByGroup } from "../Services/SubscriptionsService";
//#endregion

import { DateTimeToIsoString } from "./TimeConverter";
import { getTranslations } from "./TranslationController";
//#endregion

export const PrepareGroupedPdf = async (
  centerId,
  groupInfos,
  notationType,
  translations,
  groupWithDate,
  customFields,
  centerName
) => {
  const groupedPdfData = await GetResultsByGroup(centerId, groupInfos);
  const pdfInfos = await GetPdfInfos(centerId);
  groupInfos.startGroup = new Date(groupInfos.startGroup);
  groupInfos.endGroup = new Date(groupInfos.endGroup);
  const groupedPdf = (
    <GroupedPdf
      groupInfos={groupInfos}
      groupedPdfData={groupedPdfData}
      notationType={notationType}
      translations={translations}
      groupStart={groupInfos.startGroup}
      groupEnd={groupInfos.endGroup}
      groupWithDate={groupWithDate}
      customFields={groupInfos.showCustomFields ? customFields : []}
      centerName={centerName}
      pdfInfos={pdfInfos}
    />
  );
  const renderPdf = await pdf(groupedPdf).toBlob();
  saveAs(renderPdf, groupInfos.groupName);
};

export const PrepareCustomPdf = async (
  notationType,
  translations,
  accessStart,
  accessEnd,
  sessionDates,
  customFields,
  pdfTemplate,
  studentResult,
  centerId
) => {
  const pdfInfos = await GetPdfInfos(centerId);
  const document = (
    <CustomPdf
      pdfData={studentResult}
      accessStart={accessStart}
      accessEnd={accessEnd}
      sessionDates={sessionDates}
      translations={translations}
      notationType={notationType}
      customFields={customFields}
      pdfTemplate={pdfTemplate}
      pdfInfos={pdfInfos}
    />
  );
  const renderPdf = await pdf(document).toBlob();
  saveAs(renderPdf, getPdfName(studentResult));
};

export const getPdfName = (student) => {
  return `${student.firstName}_${student?.lastName ?? student?.name}_${DateTimeToIsoString(new Date())}.pdf`;
};

export const getNotificationMessage = (student, translations) => {
  return `${getTranslations("common_the_pdf", translations)}${getPdfName(student)} ${getTranslations(
    "common_available_in_donwload_folder",
    translations
  )}`;
};
