import { COLOR } from "./CustomPdf.constants";

export const getNoteClass = (baseClass, averagePercent, color) => {
  const getColor = () => {
    if (averagePercent <= 35) return COLOR.red;
    if (averagePercent <= 70) return COLOR.orange;
    return COLOR.green;
  };

  const property = color ? 'color' : 'backgroundColor';
  return { ...baseClass, [property]: getColor() };
};

export const getIconPath = (iconName) => {
 return `${process.env.PUBLIC_URL}/Images/icons/${iconName}.png`
}
