//#region react import
import { StyleSheet, Text, View } from "@react-pdf/renderer";
//#endregion

//#region functions import
import { getNotePdf } from "../../../Helper/NoteConverter";
import { FORMAT_TIMESTRING, formatSecondToStringTime } from "../../../Helper/TimeConverter";
import { getTranslations } from "../../../Helper/TranslationController";
import { getNoteClass } from "./CustomPdf.functions";
//#endregion

//#region constants import
import { COLOR, FONT_SIZE } from "./CustomPdf.constants";
//#endregion

const MachineTrainingResult = ({ trainingResults, freeTotalSecondElapsed, translations, notationType }) => {
  //#region style
  const styles = StyleSheet.create({
    machineTrainingResults: {
      width: "100%",
    },
    trainingTableHeader: {
      display: "flex",
      flexDirection: "row",
      backgroundColor: COLOR.grey,
      color: COLOR.textColorInversed,
      justifyContent: "space-between",
      paddingVertical: "5px",
    },
    tableLign: {
      flexDirection: "row",
      fontSize: FONT_SIZE.textSize,
      alignItems: "center",
    },
    noteCell: {
      borderRadius: "5px",
      color: COLOR.textColorInversed,
      paddingVertical: "5px",
      paddingHorizontal: "8px",
    },
    tableCell: {
      textAlign: "center",
      width: "100%",
      flexDirection: "row",
      justifyContent: "center",
      paddingVertical: "10px",
      paddingHorizontal: "4px",
    },
  });
  //#endregion
  return (
    <View style={styles.machineTrainingResults}>
      {trainingResults && trainingResults.length > 0 && (
        <>
          <View
            style={styles.trainingTableHeader}
            wrap={false}
          >
            <Text style={styles.tableCell}>{getTranslations("pdf_result_per_machine_training", translations)}</Text>
            <Text style={styles.tableCell}>{getTranslations("common_duration", translations)}</Text>
            <Text style={styles.tableCell}>{getTranslations("pdf_result_per_machine_progress", translations)}</Text>
            <Text style={styles.tableCell}>{getTranslations("student_detail_average", translations)}</Text>
          </View>
          <View
            wrap={false}
            key={"machine_result_free_mode"}
            style={{ ...styles.tableLign, backgroundColor: COLOR.white }}
          >
            <Text style={styles.tableCell}>{getTranslations("free_mode", translations)}</Text>
            <Text style={styles.tableCell}>
              {formatSecondToStringTime(freeTotalSecondElapsed, translations, FORMAT_TIMESTRING.HHMM_IGNOR_S)}
            </Text>
            <Text style={styles.tableCell}>---</Text>
            <Text style={styles.tableCell}>---</Text>
          </View>
          {trainingResults.map((trainingResult, index) => (
            <View
              wrap={false}
              key={trainingResult.trainingId}
              style={{ ...styles.tableLign, backgroundColor: index % 2 === 0 ? COLOR.white : COLOR.orangeLight }}
            >
              <Text style={styles.tableCell}>{getTranslations(trainingResult.languageKey, translations)}</Text>
              <Text style={styles.tableCell}>
                {formatSecondToStringTime(
                  trainingResult.totalSecondElapsed,
                  translations,
                  FORMAT_TIMESTRING.HHMM_IGNOR_S
                )}
              </Text>
              <Text style={styles.tableCell}>{`${trainingResult.currentStepOrder} / ${trainingResult.stepCount}`}</Text>
              <View style={styles.tableCell}>
                {trainingResult.averagePercent || trainingResult.averagePercent === 0 ? (
                  <Text style={getNoteClass(styles.noteCell, trainingResult.averagePercent)}>
                    {getNotePdf(trainingResult.averagePercent, notationType)}
                  </Text>
                ) : (
                  <Text>---</Text>
                )}
              </View>
            </View>
          ))}
        </>
      )}
    </View>
  );
};

export default MachineTrainingResult;
