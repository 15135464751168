//#region react import
import React, { useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
//#endregion

//#region component import
import ActionsGrouped from "../../../Components/ActionsGrouped/ActionsGrouped.component";
import ExportPdfModal from "../../../Components/ExportPdfModal/ExportPdfModal.component";
import GstaLoaderPage from "../../../Components/GstaLoaderPage/GstaLoaderPage.component";
import GstaModal from "../../../Components/GstaModal/GstaModal.component";
import AddTrainingModal from "../../Training/AddTrainingModal/AddTrainingModal.component";
import ProfileModal from "../ProfileModal/ProfileModal.component";
//#endregion

//#region function import
import { getTranslations } from "../../../Helper/TranslationController";
//#endregion

//#region service import
import { getCenterSettings } from "../../../Services/SettingsService";
import { setArchivedStudents, setSimulatorAccess } from "../../../Services/StudentService";
import {
  closeStudentSubscription,
  createStudentSubscription,
  getStudentSubscriptions,
  studentResubscription,
} from "../../../Services/SubscriptionsService";
//#endregion

//#region store import
import { setErrorNotification, setSuccessNotification } from "../../../ReduxStore/notificationSlice";
//#endregion

//#region constants import
import { EXPORT_TYPE } from "../../../Components/ExportPdfModal/ExportPdfModal.constants";
import { STUDENT_INSCRIPTION } from "../../../Constants/AddtrainingModalType";
import { ICON_NOTE_ADD } from "../../../Constants/IconConstants";
import { MANAGE_STUDENTS_ACCESS_WITH_DATES } from "../../../Constants/SettingsConstants";
//#endregion

//#region import style
import "./StudentGroupedActions.style.scss";
//#endregion

const StudentGroupedActions = ({ values, selectedObject, setValue, GroupedActionProps }) => {
  //#region state
  const [trainingModalOpen, setTrainingModalOpen] = useState(false);
  const [profilModalOpen, setprofileModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const [trainingsToUnsubscribe, setTrainingsToUnsubscribe] = useState([]);
  const [trainingsToResubscribe, setTrainingsToResubscribe] = useState([]);
  const [managesWithDates, setManageWithDates] = useState(false);
  const [archivedStudentsModalOpen, setArchivedStudentsModalOpen] = useState(false);
  const [selectedTrainingToSubscribe, setSelectedTrainingToSubscribe] = useState([]);
  const [exportPdfModalOpen, setExportPdfModalOpen] = useState(false);
  //#endregion

  //#region redux store
  const centerId = useSelector((state) => state.connexionSlice.trainer?.activeCenter?.id);
  const translations = useSelector((state) => state.translationSlice.translations);
  const dispatch = useDispatch();
  //#endregion

  const getSettings = async () => {
    try {
      const manageWithDatesValue = await getCenterSettings(centerId, MANAGE_STUDENTS_ACCESS_WITH_DATES);
      setManageWithDates(manageWithDatesValue.value === "true");
    } catch (e) {
      dispatch(setErrorNotification(e));
    }
  };

  useQuery({
    queryFn: getSettings,
  });

  const selectedStudents = values.filter((value) => selectedObject.find((obj) => obj === value.id));
  const selectedStudentsCount = selectedStudents.length;

  const handleChangeActivateStudent = async (newValue) => {
    let selectedStudent = null;
    let newStudent;
    let itsOk = false;
    const newStudents = [];
    try {
      for (let selectedObjectValue of selectedObject) {
        selectedStudent = values.find((student) => student.id === selectedObjectValue);
        if (selectedStudent.isActive !== newValue) {
          newStudent = { ...selectedStudent };
          newStudent.isActive = (await setSimulatorAccess(centerId, selectedStudent.id, newValue)).dataModified;
          newStudents.push(newStudent);
          itsOk = true;
        }
      }
      GroupedActionProps.setValues(newStudents);
    } catch (e) {
      dispatch(setErrorNotification(e));
    }
    return itsOk;
  };

  const handleActivateSelectedStudent = async () => {
    if (await handleChangeActivateStudent(true)) {
      dispatch(
        setSuccessNotification(getTranslations("notification_student_grouped_actions_simulator_access", translations))
      );
    }
  };

  const handleDeactivateSelectedStudent = async () => {
    if (await handleChangeActivateStudent(false)) {
      dispatch(
        setSuccessNotification(getTranslations("notification_student_grouped_actions_simulator_deny", translations))
      );
    }
  };

  const handleOpenTrainingModal = async () => {
    if (selectedObject.length === 1) {
      setTrainingsToUnsubscribe([]);
      setTrainingsToResubscribe([]);
      const studentSubscriptions = await getStudentSubscriptions(centerId, selectedObject[0]);
      setSubscriptions(studentSubscriptions);
    }
    setSelectedTrainingToSubscribe([]);
    setTrainingModalOpen(true);
  };

  const handleCloseTrainingModal = () => {
    setTrainingModalOpen(false);
  };

  const handleSubscribeAllStudentToTraining = (studentId) => {
    const subscriptionsToCreate = [];
    selectedTrainingToSubscribe.forEach((trainingId) => {
      subscriptionsToCreate.push({ studentId: studentId, trainingId: trainingId });
    });
    try {
      createStudentSubscription(centerId, subscriptionsToCreate);
      if (selectedObject.length === 1) {
        closeStudentSubscription(centerId, trainingsToUnsubscribe);
        studentResubscription(centerId, trainingsToResubscribe);
      }

      dispatch(setSuccessNotification(getTranslations("notification_student_training_inscription", translations)));
    } catch (e) {
      dispatch(setErrorNotification(e));
    }
    setLoading(false);
  };

  const handleClickModifyProfileInformation = () => {
    setprofileModalOpen(true);
  };

  const handleValidateChangeArchivedValue = async () => {
    try {
      await setArchivedStudents(centerId, selectedObject, GroupedActionProps?.archived ? false : true);
      GroupedActionProps.removeStudents(selectedObject);
      setArchivedStudentsModalOpen(false);
      dispatch(
        setSuccessNotification(
          GroupedActionProps?.archived ? "student_unarchived_successfully" : "student_archived_successfully",
          translations
        )
      );
    } catch (e) {
      dispatch(setErrorNotification(e));
    }
  };

  const actionList = [];

  if (!GroupedActionProps?.archived) {
    actionList.push({
      action: () => setExportPdfModalOpen(true),
      iconClass: "icon-pdf",
      text: getTranslations("student_grouped_actions_pdf_export", translations),
    });
    actionList.push({
      action: handleClickModifyProfileInformation,
      iconClass: "icon-pencil",
      text: getTranslations("personnalized_field_grouped_action", translations),
    });
    actionList.push({
      action: handleOpenTrainingModal,
      iconClass: ICON_NOTE_ADD,
      text: getTranslations("student_grouped_actions_inscription", translations),
    });
    if (!managesWithDates) {
      actionList.unshift({
        action: handleDeactivateSelectedStudent,
        iconClass: "icon-switch switch-off",
        text: getTranslations("student_grouped_actions_simulator_deny", translations),
      });
      actionList.unshift({
        action: handleActivateSelectedStudent,
        iconClass: "icon-switch switch-on",
        text: getTranslations("student_grouped_actions_simulator_access", translations),
      });
    }
  } else {
    actionList.push({
      action: handleOpenTrainingModal,
      iconClass: ICON_NOTE_ADD,
      text: getTranslations("student_grouped_actions_inscription", translations),
    });
  }
  actionList.push({
    action: () => setArchivedStudentsModalOpen(true),
    iconClass: GroupedActionProps?.archived ? "icon-unarchive" : "icon-archive",
    text: getTranslations(
      GroupedActionProps?.archived ? "unarchive_students_modal_title" : "archive_students_modal_title",
      translations
    ),
  });
  //#endregion
  return (
    <div className={"student-grouped-actions-container"}>
      {exportPdfModalOpen && (
        <ExportPdfModal
          modalOpen={exportPdfModalOpen}
          setModalOpen={setExportPdfModalOpen}
          studentsSelected={selectedObject}
          defaultTabSelected={selectedObject.length === 1 ? EXPORT_TYPE.INDIVIDUAL : EXPORT_TYPE.GROUP}
          pdfTemplates={GroupedActionProps.pdfTemplates}
          setPdfTemplates={GroupedActionProps.setPdfTemplates}
          selectedPeriod={GroupedActionProps.selectedPeriod}
          setSelectedPeriod={GroupedActionProps.setSelectedPeriod}
          selectedPdfTemplate={GroupedActionProps.selectedPdfTemplate}
          setSelectedPdfTemplate={GroupedActionProps.setSelectedPdfTemplate}
          personalizedStartDate={GroupedActionProps.personalizedStartDate}
          setPersonalizedStartDate={GroupedActionProps.setPersonalizedStartDate}
          personalizedEndDate={GroupedActionProps.personalizedEndDate}
          setPersonalizedEndDate={GroupedActionProps.setPersonalizedEndDate}
        />
      )}
      {loading && (
        <GstaLoaderPage LoadingText={getTranslations("student_grouped_actions_pdf_generation", translations)} />
      )}
      <p className="grouped-action-text">
        {`${selectedStudentsCount} ${getTranslations("student_grouped_actions_selected_elements", translations)}`}
      </p>
      <ActionsGrouped
        appearanceClass={"gsta-button-fill"}
        content={
          <>
            <span>{getTranslations("student_grouped_actions_label", translations)}</span>
            <i className={"icon-expand_less dropdown"} />
          </>
        }
        overrideClick={() => {
          document.querySelector(".icon-expand_less").classList.toggle("dropup");
          document.querySelector(".icon-expand_less").classList.toggle("dropdown");
        }}
        actionsList={actionList}
      />

      {trainingModalOpen && (
        <AddTrainingModal
          type={selectedObject.length === 1 ? STUDENT_INSCRIPTION : undefined}
          subscriptions={selectedObject.length === 1 ? subscriptions : undefined}
          selectedTrainingToSubscribe={selectedTrainingToSubscribe}
          setSelectedTrainingToSubscribe={setSelectedTrainingToSubscribe}
          selectedTrainingToReSubscribe={trainingsToResubscribe}
          setSelectedTrainingToReSubscribe={setTrainingsToResubscribe}
          selectedTrainingToUnsubscribe={trainingsToUnsubscribe}
          setSelectedTrainingToUnsubscribe={setTrainingsToUnsubscribe}
          modalOpen={trainingModalOpen}
          setModalOpen={setTrainingModalOpen}
          handleModalClose={handleCloseTrainingModal}
          validateAction={handleSubscribeAllStudentToTraining}
          students={selectedStudents}
          title={getTranslations("add_training_modal_multiple_student_training_inscription", translations)}
          setValues={GroupedActionProps.setValues}
        />
      )}
      {archivedStudentsModalOpen && (
        <GstaModal
          modalOpen={archivedStudentsModalOpen}
          setModalOpen={setArchivedStudentsModalOpen}
          cancelText={getTranslations("common_cancel", translations)}
          validText={getTranslations("common_validate", translations)}
          handleCloseModal={() => setArchivedStudentsModalOpen(false)}
          handleValidate={handleValidateChangeArchivedValue}
          title={getTranslations(
            GroupedActionProps?.archived ? "unarchive_students_modal_title" : "archive_students_modal_title",
            translations
          )}
        >
          <div className="archived-unarchived-students_modal-content">
            <span>
              {getTranslations(
                GroupedActionProps?.archived ? "unarchive_students_modal_text" : "archive_students_modal_text",
                translations
              )}
            </span>
            <span>{`${selectedStudentsCount} ${getTranslations(
              "personnalized_field_students_selected",
              translations
            )}`}</span>
          </div>
        </GstaModal>
      )}
      {profilModalOpen && (
        <ProfileModal
          profilModalOpen={profilModalOpen}
          closeModal={() => setprofileModalOpen(false)}
          selectedStudents={selectedObject}
          setValues={GroupedActionProps.setValues}
        />
      )}
    </div>
  );
};

export default StudentGroupedActions;
