export const TRANSLATIONS = {
  STUDENT_DETAIL_TRAINING_MACHINE_TOTAL_TIME_ELAPSED: "student_detail_training_machine_total_time_elapsed",
  CURSUS_MOD: "cursus_mod",
  FREE_MODE: "free_mode",
  STUDENT_DETAIL_TRAINING_MACHINE_AVERAGE: "student_detail_training_machine_average",
  STUDENT_DETAIL_TRAINING_SUBSCRIPTIONS: "student_detail_training_subscriptions",
  RESULT_DETAIL_NOT_STARTED: "result_detail_not_started",
  RESULT_DETAIL_NOT_STARTED_TAG: "result_detail_not_started_tag",
  STUDENT_DETAIL_TRAINING_AVERAGE: "student_detail_training_training_average",
  PDF_RESULT_PER_MACHINE_PROGRESS: "pdf_result_per_machine_progress",
  ARCHIVED_TRAINING: "archived_training",
  STUDENT_DETAIL_IN_PROGRESS: "student_detail_in_progress",
  STUDENT_DETAIL_FINISHED: "student_detail_finished",
  STUDENT_DETAIL_TRAINING_TIME: "student_detail_training_time",
  STUDENT_DETAIL_AVERAGE: "student_detail_average",
  STUDENT_DETAIL_TRAINING_PROGRESSION: "student_detail_training_progression",
  STUDENT_DETAIL_NO_TRAINING_INSCRIPTION: "student_detail_no_training_inscription",
  STUDENT_DETAIL_TRAINING: "student_detail_training",
  COMMON_DEFAULT_TIME: "common_default_time",
  SIMULATION_PLANNING: "simulation_planning",
  OF_SIMULATION: "of_simulation",
  PERSONNALIZED_FIELD_DATE: "personnalized_field_date",
  FREE_TIME_MOD: "free_time_mod",
  TOTAL: "total",
  ERROR_GET_MONTHLY_ACTIVITY: "error_get_monthly_activity",
  HOME_LOADING: "home_loading",
  HOME_MONTH_ACTIVITY_HISTORY: "home_month_activity_history",
  HOME_MONTH_ACTIVITY_HISTORY_INFORMATIONS: "home_month_activity_history_informations",
  HOME_STUDENT_CONNECTED: "home_student_connected",
  HOME_STUDENT_SIMULATOR_TIME_AVERAGE: "home_student_simulator_time_average",
  CURSUS_TIME: "cursus_time",
  IMPROVEMENT_IDEA: "improvement_idea"
};