//#region react import
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
//#endregion

//#region functions import
import { getTrainingLoop } from "../../Constants/AddTrainingModalFunctions";
import ScrollToTopController from "../../Helper/CustomHook/ScrollToTopController";
import { FORMAT_TIMESTRING, formatSecondToStringTime } from "../../Helper/TimeConverter";
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region components import
import GstaLoaderPage from "../../Components/GstaLoaderPage/GstaLoaderPage.component.jsx";
import GstaTable from "../../Components/GstaTable/GstaTable.component";
import PageWithTitle from "../../Components/PageWithTitle/PageWithTitle.component";
import RedirectionLink from "../../Components/RedirectionLink/RedirectionLink.component.jsx";
import TrainingAction from "../../Pages/Training/TrainingAction/TrainingAction.component";
import TrainingStepItem from "../../Pages/Training/TrainingItem/TrainingItem.component";
import TrainingMachineItem from "../../Pages/Training/TrainingMachineItem/TrainingMachineItem.component";
import TrainingNameItem from "../../Pages/Training/TrainingNameItem/TrainingNameItem.component";
//#endregion

//#region services import
//#endregion

//#region store import
import { setSelectedTrainingId, setTrainings } from "../../ReduxStore/trainingSlice";
//#endregion

//#region constants import
import { GSTA_TABLE_IDS } from "../../Components/GstaTable/GstaTable.constants";
import { GET_TRAINING } from "../../Constants/ApiConstants.js";
import { FILTERED, ORDERED } from "../../Constants/HeaderType";
import KeyTrad_NumberPerPage from "../../Constants/PaginationConstants.js";
import { TRAININGS_ARCHIVED, TRAININGS_CHOOSE_MACHINE, TRAININGS_DETAILS } from "../../Routing/PageNames";
//#endregion

//#region style import
import { ICON_LOGIN, ICON_NOTE_ADD, ICON_RESTART } from "../../Constants/IconConstants.js";
import "./TrainingLayout.style.scss";
//#endregion

/**
 * Component created to render the layout of the training
 */

const TrainingLayout = () => {
  //#region others use...
  const translations = useSelector((state) => state.translationSlice.translations);
  const navigate = useNavigate();

  const activeCenter = useSelector((state) => state.connexionSlice.trainer?.activeCenter);
  const trainings = useSelector((state) => state.trainingSlice.trainings ?? []);
  const traingsCount = useSelector((state) => state.trainingSlice.totalElement);
  const dispatch = useDispatch();
  //#endregion

  //#region state
  const [trainingsState, setTrainingState] = useState(trainings);
  const [loading, setLoading] = useState();
  //#endregion

  //#region functions
  const getTrainingStudent = (training) => {
    return training.studentCount;
  };
  const getTrainingName = (training) => {
    return getTranslations(training.trainingName, translations);
  };

  const getMachineName = (training) => {
    return getTranslations(training.machineName, translations);
  };

  const getTrainingDuration = (training) => {
    return training.duration;
  };

  const getTrainingStep = (training) => {
    return training.stepCount;
  };

  const getTrainingActivity = (training) => {
    return training.lastTraininigDate ? new Date(training.lastTraininigDate).toLocaleDateString() : "";
  };

  const redirectToDetail = (value) => {
    dispatch(setSelectedTrainingId(value.id));
    navigate(TRAININGS_DETAILS.url);
  };

  const redirectToChooseMachine = () => {
    navigate(TRAININGS_CHOOSE_MACHINE.url);
  };

  //#endregion

  //#region constants
  const trainingStepHeaderDefinition = [
    {
      columnName: getTranslations("common_training_name", translations),
      type: ORDERED,
      getValue: getTrainingName,
      expandedColumn: false,
      searchColumn: true,
      overrideColumn: TrainingNameItem,
      overrideProps: {
        image: process.env.PUBLIC_URL + "Images/BUS/ressources/img/BUS_0.png",
        getValue: getTrainingName,
        showImg: true,
      },
    },
    {
      columnName: getTranslations("common_machine", translations),
      type: FILTERED,
      getValue: getMachineName,
      expandedColumn: false,
      searchColumn: true,
      overrideColumn: TrainingMachineItem,
    },
    {
      columnName: getTranslations("common_duration", translations),
      type: ORDERED,
      getValue: getTrainingDuration,
      expandedColumn: false,
      overrideColumn: TrainingStepItem,
      overrideProps: {
        image: "timer",
        getValue: (value) => formatSecondToStringTime(getTrainingDuration(value), translations, FORMAT_TIMESTRING.HHMM),
      },
    },
    {
      columnName: getTranslations("common_steps", translations),
      type: ORDERED,
      getValue: getTrainingStep,
      expandedColumn: false,
      overrideColumn: TrainingStepItem,
      overrideProps: {
        image: "training",
        getValue: getTrainingStep,
      },
    },
    {
      columnName: getTranslations("common_students", translations),
      type: ORDERED,
      getValue: getTrainingStudent,
      expandedColumn: false,
      overrideColumn: TrainingStepItem,
      overrideProps: {
        image: "helmet",
        getValue: (value) =>
          value.studentCount + " " + getTranslations("training_layout_student_registered", translations),
      },
    },
    {
      columnName: getTranslations("training_layout_last_activity", translations),
      type: ORDERED,
      getValue: getTrainingActivity,
      expandedColumn: false,
    },
  ];
  //#endregion

  //#region useQuery
  const { isFetching } = useQuery({
    queryKey: ["training"],
    queryFn: async () => {
      const alltrainings = await getTrainingLoop(
        activeCenter.id,
        GET_TRAINING.NOT_ARCHIVED,
        dispatch,
        setTrainingState
      );
      dispatch(setTrainings(alltrainings));
    },
    enabled: trainings.length === 0,
    refetchOnWindowFocus: false,
  });

  //#endregion

  //#region useEffect
  useEffect(() => {
    if (trainings !== trainingsState) {
      setTrainingState(trainings);
    }
  }, [trainings, trainingsState]);
  //#endregion
  return (
    <div className="training-page">
      {loading && <GstaLoaderPage />}
      <ScrollToTopController />
      <PageWithTitle
        title={
          <>
            <div>
              <span>{getTranslations("training_layout_training_catalog", translations)}</span>
              <RedirectionLink
                onLinkClick={() => {
                  navigate(TRAININGS_ARCHIVED.url);
                }}
                translation={getTranslations("link_archived_trainings", translations)}
                icon={ICON_LOGIN}
              />
            </div>
            <button
              disabled={isFetching}
              onClick={redirectToChooseMachine}
              className="gsta-button-fill"
            >
              <i className={ICON_NOTE_ADD} />
              <span>{getTranslations("personnalized_training_create", translations)}</span>
              {isFetching && <i className={ICON_RESTART} />}
            </button>
          </>
        }
      >
        <GstaTable
          values={trainingsState}
          headerDefinitions={trainingStepHeaderDefinition}
          totalElementsCount={traingsCount}
          Actions={TrainingAction}
          ActionsProps={{
            isArchivedTrainingPage: false,
            setLoading: setLoading,
            setTrainingState: setTrainingState,
            trainingsState: trainingsState,
          }}
          SearchInputPlaceHolder={getTranslations("student_find_student_placeholder", translations)}
          onRowClick={redirectToDetail}
          noResultPlaceHolder={getTranslations("common_no_training_found", translations)}
          numberPerPageText={KeyTrad_NumberPerPage.Training}
          pagination
          globalSearch
          tableId={GSTA_TABLE_IDS.TRAINING_TABLE}
        />
      </PageWithTitle>
    </div>
  );
};
export default TrainingLayout;
