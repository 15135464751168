//#region react import
import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { Fragment } from "react";
//#endregion

//#region functions import
import { DateTimeToIsoString, FORMAT_TIMESTRING, formatSecondToStringTime } from "../../../Helper/TimeConverter";
import { getTranslations } from "../../../Helper/TranslationController";
//#endregion

//#region constants import
import { MONTHS } from "../../../Constants/DateConstants";
import { TRANSLATIONS } from "../../../Constants/Translations";
import { COLOR, FONT_SIZE } from "./CustomPdf.constants";
//#endregion

const SimulationPlanning = ({ calendar, translations }) => {
  //#region style
  const styles = StyleSheet.create({
    planningSection: {
      borderColor: COLOR.orange,
      borderStyle: "solid",
      borderWidth: "2px",
      marginBottom: "30px",
    },

    simulationPlanning: {
      width: "100%",
    },
    mainTitle: {
      width: "100%",
      backgroundColor: COLOR.orangeLight,
      textAlign: "center",
      fontSize: FONT_SIZE.mainTitle,
      paddingVertical: "5px",
    },
    monthTitle: {
      width: "100%",
      backgroundColor: COLOR.orange,
      textAlign: "center",
      paddingVertical: "8px",
      fontSize: FONT_SIZE.subtitle,
      flexDirection: "row",
      justifyContent: "center",
      gap: "5px",
    },
    monthTitleBold: {
      fontWeight: "bold",
    },
    calendarHeader: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      backgroundColor: COLOR.grey,
      color: COLOR.textColorInversed,
      paddingVertical: "8px",
      fontWeight: "bold",
    },
    tableLign: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      paddingVertical: "12px",
    },
    tableCell: {
      width: "100%",
      textAlign: "center",
      fontSize: "11px",
    },
  });
  //#endregion
  //#region functions
  const getCalendarGroupByYearAndMounth = () => {
    const calendarGroupByYearMonth = {};
    calendar?.forEach((calendarDate) => {
      const date = new Date(calendarDate.activityDate);
      if (!calendarGroupByYearMonth[date.getFullYear()]) {
        calendarGroupByYearMonth[date.getFullYear()] = {};
      }
      if (!calendarGroupByYearMonth[date.getFullYear()][date.getMonth()]) {
        calendarGroupByYearMonth[date.getFullYear()][date.getMonth()] = [];
      }
      calendarGroupByYearMonth[date.getFullYear()][date.getMonth()].unshift(calendarDate);
    });
    return calendarGroupByYearMonth;
  };

  const getCursusMonthTimeSimulation = (timeData) => {
    let totalCursusTime = 0;
    timeData.forEach((time) => (totalCursusTime += time.duration));
    return totalCursusTime;
  };

  const getFreeMonthTimeSimulation = (timeData) => {
    let totalFreeTime = 0;
    timeData.forEach((time) => (totalFreeTime += time.freeDuration));
    return totalFreeTime;
  };

  const getTotalMonthTimeSimulation = (timeData) => {
    return formatSecondToStringTime(
      getCursusMonthTimeSimulation(timeData) + getFreeMonthTimeSimulation(timeData),
      translations,
      FORMAT_TIMESTRING.HHMM_IGNOR_S
    );
  };
  //#endregion

  //#region constants
  const calendarGroupByYearAndMonth = getCalendarGroupByYearAndMounth();
  //#endregion

  return (
    <View style={styles.planningSection}>
      <Text style={styles.mainTitle}>{getTranslations(TRANSLATIONS.SIMULATION_PLANNING, translations)}</Text>
      <View style={styles.simulationPlanning}>
        {Object.keys(calendarGroupByYearAndMonth).map((year) =>
          Object.keys(calendarGroupByYearAndMonth[year]).map((month) => {
            return (
              <Fragment key={`${year}-${month}`}>
                <View style={styles.monthTitle}>
                  <Text style={styles.monthTitleBold}>{`${getTranslations(
                    MONTHS.find((monthTrad) => parseInt(month) + 1 === monthTrad.number)?.traductionKey ?? month,
                    translations
                  ).toUpperCase()} `}</Text>
                  <Text>{`${year} - ${getTotalMonthTimeSimulation(
                    calendarGroupByYearAndMonth[year][month]
                  )} ${getTranslations(TRANSLATIONS.OF_SIMULATION, translations)}`}</Text>
                </View>
                <View style={styles.calendarHeader}>
                  <Text style={styles.tableCell}>
                    {getTranslations(TRANSLATIONS.PERSONNALIZED_FIELD_DATE, translations)}
                  </Text>
                  <Text style={styles.tableCell}>{getTranslations(TRANSLATIONS.CURSUS_TIME, translations)}</Text>
                  <Text style={styles.tableCell}>{getTranslations(TRANSLATIONS.FREE_TIME_MOD, translations)}</Text>
                </View>
                {calendarGroupByYearAndMonth[year][month].map((calendarValue, index) => (
                  <View
                    style={{ ...styles.tableLign, backgroundColor: index % 2 === 0 ? COLOR.white : COLOR.orangeLight }}
                    key={calendarValue.activityDate}
                  >
                    <Text style={styles.tableCell}>{DateTimeToIsoString(new Date(calendarValue.activityDate))}</Text>
                    <Text style={styles.tableCell}>
                      {formatSecondToStringTime(calendarValue.duration, translations, FORMAT_TIMESTRING.HHMM_IGNOR_S)}
                    </Text>
                    <Text style={styles.tableCell}>
                      {formatSecondToStringTime(
                        calendarValue.freeDuration,
                        translations,
                        FORMAT_TIMESTRING.HHMM_IGNOR_S
                      )}
                    </Text>
                  </View>
                ))}
                <View
                  style={{ ...styles.tableLign, backgroundColor: COLOR.orangeLight }}
                  key={"totalTime"}
                >
                  <Text style={styles.tableCell}>{getTranslations(TRANSLATIONS.TOTAL, translations)}</Text>
                  <Text style={styles.tableCell}>
                    {formatSecondToStringTime(
                      getCursusMonthTimeSimulation(calendarGroupByYearAndMonth[year][month]),
                      translations,
                      FORMAT_TIMESTRING.HHMM_IGNOR_S
                    )}
                  </Text>
                  <Text style={styles.tableCell}>
                    {formatSecondToStringTime(
                      getFreeMonthTimeSimulation(calendarGroupByYearAndMonth[year][month]),
                      translations,
                      FORMAT_TIMESTRING.HHMM_IGNOR_S
                    )}
                  </Text>
                </View>
              </Fragment>
            );
          })
        )}
      </View>
    </View>
  );
};

export default SimulationPlanning;
