//#region react import
import { Fragment, useCallback, useEffect } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
//#endregion

//#region components import
import MachineResultSummary from "../../../Components/MachineResultSummary/MachineResultSummary.component";
import StudentStepDetails from "../StudentStepDetails/StudentStepDetails.component";
import TrainingResult from "../TrainingResult/TrainingResult.component";
//#endregion

//#region services import
import { getCenterTrainingStepsResults } from "../../../Services/TrainingService";
//#endregion

//#region store import
import { setErrorNotification } from "../../../ReduxStore/notificationSlice";
//#endregion

//#region constants import
import { TRANSLATIONS } from "../../../Constants/Translations";
import { STUDENTS_TRAINING } from "../../../Routing/PageNames";
import { EXAMINE_MODULE_NOT_ACTIVATED } from "../../Home/GstaCalendar/GstaCalendar.constants";
//#endregion

//#region functions import
import useNotationType from "../../../Helper/CustomHook/useNotationType";
import { FORMAT_TIMESTRING, formatSecondToStringTime } from "../../../Helper/TimeConverter";
import { getTranslations } from "../../../Helper/TranslationController";
//#endregion

//#region style import
import "../TrainingResult/TrainingResult.style.scss";
import "./MachineResult.style.scss";
//#endregion

const MachineResult = ({ machineResult }) => {
  //#region react-router
  const location = useLocation();
  //#endregion

  //#region constants
  //#endregion

  //#region use...
  const translations = useSelector((state) => state.translationSlice.translations);
  const notationType = useSelector((state) => state.settingSlice.notationType);
  const centerId = useSelector((state) => state.connexionSlice.trainer?.activeCenter?.id);
  const subscriptionId = useSelector((state) => state.subscriptionSlice.subscriptionId);
  const stepId = useSelector((state) => state.subscriptionSlice.currentStepId);
  const examineModule = useSelector((state) => state.settingSlice.settingDictionary.EXAMINE_MODULES_EXTENDED);
  const dispatch = useDispatch();
  //#endregion

  //#region functions
  useNotationType(dispatch, centerId, notationType);
  //#endregion

  //#region useQuery
  const { data, isError, isLoading, refetch } = useQuery({
    queryKey: ["stepDetails", centerId, subscriptionId, stepId],
    queryFn: async () => {
      try {
        if (subscriptionId && stepId) return await getCenterTrainingStepsResults(centerId, subscriptionId, stepId);
      } catch (e) {
        dispatch(setErrorNotification(e));
      }
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: false,
  });
  //endregion

  //#region useEffect
  const renderDetails = useCallback(
    (trainingResult) => {
      if (subscriptionId && trainingResult.subscriptionId === subscriptionId) {
        return (
          <StudentStepDetails
            isError={isError}
            isLoading={isLoading}
            data={data}
            steps={trainingResult.stepAverages}
          />
        );
      }
    },
    [data, isError, isLoading, subscriptionId]
  );

  useEffect(() => {
    if (location.pathname.includes(STUDENTS_TRAINING.url) && ((subscriptionId && stepId) || isError || isLoading)) {
      const allStep = document.querySelectorAll(".circle");
      allStep.forEach((item) => {
        if (item.id === "circle_" + subscriptionId + "_" + stepId) {
          item.classList.remove("active");
        }
      });

      const allDetailsBlock = document.querySelectorAll(".result-per-machine_details");
      allDetailsBlock.forEach((item) => {
        if (item.id === "result-per-machine_details_" + subscriptionId) {
          item.classList.remove("active");
        }
      });

      const allGlobalBlock = document.querySelectorAll(".result-per-machine_global");
      allGlobalBlock.forEach((item) => {
        if (item.id === "result-per-machine_global_" + subscriptionId) {
          item.classList.remove("visible");
        }
      });
      refetch();
      if (subscriptionId && stepId) {
        document.getElementById("circle_" + subscriptionId + "_" + stepId)?.classList.add("active");
        document.getElementById("result-per-machine_details_" + subscriptionId)?.classList.add("visible");
        document.getElementById("result-per-machine_global_" + subscriptionId)?.classList.add("active");
      }
    }

    return () => {};
  }, [stepId, subscriptionId, location.pathname, refetch, isError, isLoading]);
  //#endregion

  return (
    <div className="machine-hierarchie">
      <MachineResultSummary
        machineResult={machineResult}
        translations={translations}
      />
      <ul>
        {examineModule !== EXAMINE_MODULE_NOT_ACTIVATED && (
          <li
            id={"result-per-machine_global_free"}
            className={`result-per-machine_global`}
          >
            <div className="result-per-training_steps free">
              <h1>{getTranslations(TRANSLATIONS.FREE_MODE, translations)}</h1>
              <div className={"title-part-last"}>
                <div>
                  <span className="span-title">
                    {getTranslations(TRANSLATIONS.STUDENT_DETAIL_TRAINING_MACHINE_TOTAL_TIME_ELAPSED, translations)}
                  </span>
                  <span className="gsta-span">
                    <i className="icon-hourglass" />
                    {formatSecondToStringTime(
                      machineResult.freeTotalSecondElapsed,
                      translations,
                      FORMAT_TIMESTRING.HHMM
                    )}
                  </span>
                </div>
              </div>
            </div>
          </li>
        )}
        {machineResult.trainingResults.map((trainingResult, index) => (
          <Fragment key={index}>
            <li
              id={"result-per-machine_global_" + trainingResult.subscriptionId}
              className={`result-per-machine_global`}
            >
              <TrainingResult
                trainingResult={trainingResult}
                refetch={refetch}
              />
            </li>
            {trainingResult.subscriptionId === subscriptionId && (
              <li
                id={"result-per-machine_details_" + trainingResult.subscriptionId}
                className={"result-per-machine_details"}
              >
                {renderDetails(trainingResult)}
              </li>
            )}
          </Fragment>
        ))}
      </ul>
    </div>
  );
};

export default MachineResult;
