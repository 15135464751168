//#region react import
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
//#endregion

//#region components import
import AcreosSpan from "../../../Components/AcreosSpan/AcreosSpan.component";
import GstaTable from "../../../Components/GstaTable/GstaTable.component";
import Loader from "../../../Components/Loader/Loader.component";
import PieChartNotation from "../../../Components/PieChartNotation/PieChartNotation.component";
import IconCheckCross from "../../Center/IconCheckCross/IconCheckCross.component";
//#endregion

//#region services import
import { ConvertNote } from "../../../Helper/NoteConverter";
import { FORMAT_TIMESTRING, formatSecondToStringTime } from "../../../Helper/TimeConverter";
import { getTranslations } from "../../../Helper/TranslationController";
//#endregion

//#region store import
import { setCurrentStepId, setSubscriptionId } from "../../../ReduxStore/subscriptionSlice";
//#endregion

//#region constants import
import {
  ACREOS_SPAN_STYLE_ERROR,
  ACREOS_SPAN_STYLE_ORANGE,
  ACREOS_SPAN_STYLE_OUTLINED,
  ACREOS_SPAN_STYLE_SUCCESS,
} from "../../../Components/AcreosSpan/AcreosSpan.constants";
import { STEP_PROGRESS_STATE } from "../../../Components/StepProgress/StepProgress.constants";
import { FILTERED, NONE } from "../../../Constants/HeaderType";
//#endregion

//#region style import
import "./StudentStepDetails.style.scss";
//#endregion

/**
 * section of step's details
 * depends to subscriptionId and stepId
 */
const StudentStepDetails = ({ data, isError, isLoading, steps }) => {
  //#region react-router
  const location = useLocation();
  //#endregion

  //#region use...
  const notationType = useSelector((state) => state.settingSlice.notationType);
  const translations = useSelector((state) => state.translationSlice.translations);
  const stepId = useSelector((state) => state.subscriptionSlice.currentStepId);

  const dispatch = useDispatch();
  //#endregion
  //#region constants
  const arrayNavStepOrderId = steps?.map((step) => step.trainingStepId);
  const step = steps?.find((step) => step.trainingStepId === stepId);
  const currentIndex = arrayNavStepOrderId?.findIndex((value) => value === data?.trainingStepId);

  const headerDefinition = [
    {
      columnName: getTranslations("result_detail_criterions", translations),
      type: NONE,
      getValue: (value) => getTranslations(value.criterionNameKey, translations),
      width: "25%",
    },
    {
      columnName: getTranslations("result_detail_results_students", translations),
      type: NONE,
      getValue: (value) => getAverageScore(value),
      width: "25%",
    },
    {
      columnName: getTranslations("result_detail_objective", translations),
      type: NONE,
      getValue: (value) => getScore(value),
      width: "25%",
    },
    {
      columnName: getTranslations("result_detail_validate", translations),
      type: FILTERED,
      overrideColumn: IconCheckCross,
      overrideProps: {
        getValue: (value) => {
          if (step.stepState === STEP_PROGRESS_STATE.UNKNOWN || step.stepState === STEP_PROGRESS_STATE.CURRENT) {
            return null;
          } else {
            return value.completed;
          }
        },
      },
      getValue: (value) => (value.completed ? "oui" : "non"),
      width: "25%",
    },
  ];
  //#endregion

  //#region functions
  const previousStep = useCallback(() => {
    if (currentIndex > 0) {
      const allStep = document.querySelectorAll(".circle");
      allStep.forEach((item) => item.classList.remove("active"));
      dispatch(setCurrentStepId(arrayNavStepOrderId[currentIndex - 1]));
    }
  }, [currentIndex, arrayNavStepOrderId, dispatch]);

  const nextStep = useCallback(() => {
    if (currentIndex < arrayNavStepOrderId.length) {
      const allStep = document.querySelectorAll(".circle");
      allStep.forEach((item) => item.classList.remove("active"));
      dispatch(setCurrentStepId(arrayNavStepOrderId[currentIndex + 1]));
    }
  }, [currentIndex, arrayNavStepOrderId, dispatch]);

  const clearStateLocation = () => {
    const allStep = document.querySelectorAll(".circle");
    allStep.forEach((item) => item.classList.remove("active"));

    const allDetailsBlock = document.querySelectorAll(".result-per-machine_details");
    allDetailsBlock.forEach((item) => item.classList.remove("active"));

    dispatch(setCurrentStepId(null));
    dispatch(setSubscriptionId(null));
  };

  const getProgress = () => {
    if (
      (step.stepState === STEP_PROGRESS_STATE.CURRENT || step.stepState === STEP_PROGRESS_STATE.UNKNOWN) &&
      step.totalSecondsElapsed !== null
    ) {
      return {
        text: getTranslations("result_detail_in_progress_tag", translations),
        class: ACREOS_SPAN_STYLE_ORANGE,
      };
    } else if (step.stepState === STEP_PROGRESS_STATE.SKIP) {
      return {
        text: getTranslations("result_detail_in_progress_tag", translations),
        class: "",
      };
    } else if (step.stepState === STEP_PROGRESS_STATE.FAIL) {
      return {
        text: getTranslations("result_detail_fail_tag", translations),
        class: ACREOS_SPAN_STYLE_ERROR,
      };
    } else if (step.stepState === STEP_PROGRESS_STATE.SUCCESS) {
      return {
        text: getTranslations("student_detail_finished", translations),
        class: ACREOS_SPAN_STYLE_SUCCESS,
      };
    } else {
      return {
        text: getTranslations("result_detail_not_started_tag", translations),
        class: "",
      };
    }
  };

  const getAverageScore = (value) => {
    var htmlElement;

    if (
      (step.stepState === STEP_PROGRESS_STATE.CURRENT || step.stepState === STEP_PROGRESS_STATE.UNKNOWN) &&
      step.totalSecondsElapsed === null
    ) {
      htmlElement = (
        <AcreosSpan appearanceClass={getProgress().class}>
          {getTranslations("result_detail_not_started_tag", translations)}
        </AcreosSpan>
      );
    } else if (
      (step.stepState === STEP_PROGRESS_STATE.CURRENT || step.stepState === STEP_PROGRESS_STATE.UNKNOWN) &&
      step.totalSecondsElapsed !== null &&
      step.successfullExerciseCount === 0 &&
      step.failedExerciseCount === 0
    )
      htmlElement = (
        <AcreosSpan appearanceClass={getProgress().class}>
          {getTranslations("result_detail_not_started", translations)}
        </AcreosSpan>
      );
    else {
      htmlElement = (
        <>
          <span>{value.averageScore === 0 ? 0 : value.averageScore.toFixed(1)}</span>
          <span>{value.unit?.convertedAcronym}</span>
        </>
      );
    }

    return htmlElement;
  };

  const getScore = (value) => {
    return (
      <>
        <span>{value.targetScore === 0 ? 0 : value.targetScore.toFixed(1)}</span>
        <span>{value.unit?.convertedAcronym}</span>
      </>
    );
  };

  const getName = () => {
    if (data?.customName && data.customName !== "") {
      return getTranslations(data.customName, translations);
    } else {
      return getTranslations(data?.trainingNameKey, translations);
    }
  };

  const getLastActivityDate = () => {
    const currentStep = steps.find((step) => step.trainingStepOrder === data.stepOrder);
    const lastActivityDate = currentStep.lastResult;

    if (lastActivityDate) return new Date(lastActivityDate).toLocaleDateString();
    else return getTranslations("no_activity", translations);
  };
  //#endregion

  //#region useQuery
  //endregion
  //#region useCallback / useEffect
  useEffect(() => {
    const element = document.getElementById(location.hash.replace("#", ""));

    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }

    return null;
  }, [location]);
  //#endregion
  if (isError) {
    return (
      <section className="result-per-training_steps_details error">
        <h1>{getTranslations("common_error", translations)}</h1>
        <p>{getTranslations("error_contact", translations)}</p>
      </section>
    );
  }
  if (isLoading) {
    return (
      <section className="result-per-training_steps_details loading">
        <Loader size={3} />
      </section>
    );
  }
  return (
    <section className="result-per-training_steps_details">
      <header>
        <h1>{getTranslations("result_detail_title", translations)}</h1>
        <button
          className="gsta-button-fill outline"
          onClick={clearStateLocation}
        >
          {getTranslations("result_detail_close", translations)}
          <i className="icon-expand_less" />
        </button>
      </header>
      {!isLoading && data && (
        <div>
          <nav>
            <button
              className="button--nav"
              disabled={currentIndex <= 0}
              onClick={previousStep}
            >
              <i className="icon-keyboard_arrow_left" />
            </button>
            <p>
              <AcreosSpan appearanceClass={getProgress().class}>{getProgress().text ?? ""}</AcreosSpan>
              {getTranslations("common_steps", translations)} {data?.stepOrder} - {getName()}
            </p>
            <button
              className="button--nav"
              disabled={currentIndex >= arrayNavStepOrderId?.length - 1}
              onClick={nextStep}
            >
              <i className="icon-keyboard_arrow_right" />
            </button>
          </nav>
          <div className="result-per-training_steps_details_total-time">
            <div>
              <AcreosSpan appearanceClass={ACREOS_SPAN_STYLE_OUTLINED}>
                <i className="icon-hourglass" />
                <span>
                  {formatSecondToStringTime(step.totalSecondsElapsed, translations, FORMAT_TIMESTRING.HHMMSS)}
                </span>
                <span>{getTranslations("result_detail_time_passed", translations)}</span>
              </AcreosSpan>
              <AcreosSpan appearanceClass={"outlined"}>
                {`${getTranslations("student_last_activity", translations)} : ${getLastActivityDate()}`}
              </AcreosSpan>
            </div>
            {step.stepState === STEP_PROGRESS_STATE.CURRENT ||
              (step.stepState === STEP_PROGRESS_STATE.UNKNOWN &&
                step.totalSecondsElapsed !== null &&
                step.successfullExcerciseCount !== 0 &&
                step.failedExerciseCount !== 0 && (
                  <AcreosSpan appearanceClass={"outlined"}>
                    <i className="icon-warning" />
                    <div>
                      <span>{getTranslations("non_definitive_result", translations)}</span>
                      <span>{getTranslations("definitive_results_available_after_step_finished", translations)}</span>
                    </div>
                  </AcreosSpan>
                ))}
          </div>
          <div className="result-per-training_steps_details_charts">
            <PieChartNotation
              value={data.averagePercent ?? 0}
              label={{
                value: ConvertNote(data.averagePercent, notationType),
                text: getTranslations("result_detail_mark", translations),
              }}
              inWaiting={
                step.totalSecondsElapsed !== null &&
                step.successfullExerciseCount === 0 &&
                step.failedExerciseCount === 0
              }
              notStarted={step.stepState === STEP_PROGRESS_STATE.UNKNOWN && step.totalSecondsElapsed === null}
              inProgress={
                step.stepState === STEP_PROGRESS_STATE.CURRENT ||
                (step.stepState === STEP_PROGRESS_STATE.UNKNOWN && step.totalSecondsElapsed !== null)
              }
              coefSize={0.2}
            />

            <PieChartNotation
              value={(data.completedExercicesCount / data.exercicesCount) * 100 ?? 0}
              label={{
                value: `${data.completedExercicesCount}/${data.exercicesCount}`,
                text: getTranslations("result_detail_exercices", translations),
              }}
              inWaiting={
                step.totalSecondsElapsed !== null &&
                step.successfullExerciseCount === 0 &&
                step.failedExerciseCount === 0
              }
              notStarted={step.stepState === STEP_PROGRESS_STATE.UNKNOWN && step.totalSecondsElapsed === null}
              inProgress={
                step.stepState === STEP_PROGRESS_STATE.CURRENT ||
                (step.stepState === STEP_PROGRESS_STATE.UNKNOWN && step.totalSecondsElapsed !== null)
              }
              coefSize={0.2}
            />

            <PieChartNotation
              value={
                (data.resultsCriterion.filter((res) => res.completed === true).length / data.resultsCriterion.length) *
                  100 ?? 0
              }
              label={{
                value: `${data.resultsCriterion.filter((res) => res.completed === true).length}/${
                  data.resultsCriterion.length
                }`,
                text: getTranslations("result_detail_criterion_validates", translations),
              }}
              inWaiting={
                step.totalSecondsElapsed !== null &&
                step.successfullExerciseCount === 0 &&
                step.failedExerciseCount === 0
              }
              notStarted={step.stepState === STEP_PROGRESS_STATE.UNKNOWN && step.totalSecondsElapsed === null}
              inProgress={
                step.stepState === STEP_PROGRESS_STATE.CURRENT ||
                (step.stepState === STEP_PROGRESS_STATE.UNKNOWN && step.totalSecondsElapsed !== null)
              }
              coefSize={0.2}
            />
          </div>
          <GstaTable
            headerDefinitions={headerDefinition}
            values={data.resultsCriterion ?? []}
            overrideClass={"result-per-training_steps_details_table-criterions"}
          />
        </div>
      )}
    </section>
  );
};

StudentStepDetails.propTypes = {};

export default StudentStepDetails;
