////#region react import
import React from "react";
import { useSelector } from "react-redux";
//#endregion

//#region component import
import AcreosNavigation from "../../Components/AcreosNavigation/AcreosNavigation.component";
import LogoutButton from "../../Components/LogoutButton/LogoutButton.component";
import NoticeChip from "../../Components/NoticeChip/NoticeChip.component";
import QuestionnaryChip from "../../Components/QuestionnaryChip/QuestionnaryChip.component";
import UserChip from "../../Components/UserChip/UserChip.component";
//#endregion

//#region function import
import useDocumentTitle from "../../Helper/CustomHook/useDocumentTitle";
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region constants import
import packageJson from "../../../package.json";
import { poweredbyAcreos } from "../../Constants/Images";
import {
  CENTER,
  CENTER_SIMULATORS,
  CURRENT_ACTIVITY,
  HOME,
  PROFIL,
  SELECT_ACTIVE_CENTER,
  STUDENTS,
  TRAININGS,
} from "../../Routing/PageNames";
//#endregion

//#region style import
import "./GlobalLayout.style.scss";
//#endregion

const GlobalLayout = ({ icon, children, title }) => {
  const translations = useSelector((state) => state.translationSlice.translations);
  const activeCenterName = useSelector((state) => state.connexionSlice.trainer?.activeCenter?.name);

  const dataSettingsMenuLandscape = [
    {
      key: 0,
      overrideComponent: (
        <div className="gsta-navigation-logo">
          <i className="icon-Logo_GSTA" />
          <img
            className="navigation-bar-powered-by-acreos"
            src={process.env.PUBLIC_URL + poweredbyAcreos.img}
            alt={poweredbyAcreos.alt}
          />
        </div>
      ),
    },
    {
      key: 1,
      iconClass: `icon-home navigation-button-icon`,
      url: CURRENT_ACTIVITY.url,
      matchingUrl: HOME.matchingUrl,
      title: getTranslations(HOME.pageTraductionKey, translations),
    },
    {
      key: 2,
      iconClass: `icon-groups navigation-button-icon`,
      url: STUDENTS.url,
      matchingUrl: STUDENTS.matchingUrl,
      title: getTranslations(STUDENTS.pageTraductionKey, translations),
    },
    {
      key: 3,
      iconClass: `icon-conversion_path navigation-button-icon`,
      url: TRAININGS.url,
      matchingUrl: TRAININGS.matchingUrl,
      title: getTranslations(TRAININGS.pageTraductionKey, translations),
    },
    {
      key: 4,
      iconClass: `icon-location_on navigation-button-icon`,
      url: !process.env.REACT_APP_LOCAL ? SELECT_ACTIVE_CENTER.url : CENTER_SIMULATORS.url,
      matchingUrl: CENTER.matchingUrl,
      title: getTranslations(CENTER.pageTraductionKey, translations),
      subTitle: !process.env.REACT_APP_LOCAL ? activeCenterName : null,
    },
    {
      key: 5,
      iconClass: `icon-person navigation-button-icon`,
      url: PROFIL.url,
      title: getTranslations(PROFIL.pageTraductionKey, translations),
    },
    {
      key: 6,
      overrideComponent: <LogoutButton />,
    },
  ];
  useDocumentTitle("GSTA Pro " + getTranslations(title, translations));
  return (
    <div className="global-layout">
      <div className="version">V.{packageJson.buildVersion}</div>
      <AcreosNavigation
        maxNavElement={7}
        navData={dataSettingsMenuLandscape}
        structureClass={"new-gsta-navigation landscape"}
        overrideCustomLinkClass={"gsta-navigation-link"}
      />
      <header className="title-fixed">
        <h1 className="text-icon">
          <i className={`icon-${icon}`} />
          <span>{getTranslations(title, translations)}</span>
        </h1>
        <section>
          <QuestionnaryChip />
          <NoticeChip />
          <UserChip />
        </section>
      </header>
      <section>{children}</section>
    </div>
  );
};

export default GlobalLayout;
