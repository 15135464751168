//#region react import
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
//#endregion

//#region functions
import { SetDataTestId } from "../../Helper/DataTestId";
import { ConvertNote } from "../../Helper/NoteConverter";
import { FORMAT_TIMESTRING, formatSecondToStringTime } from "../../Helper/TimeConverter";
import { getTranslations } from "../../Helper/TranslationController";
//endregion

//#region store import
import { setSelectedStudent } from "../../ReduxStore/studentsSlice";
import { setCurrentStepId, setSubscriptionId } from "../../ReduxStore/subscriptionSlice";
//#endregion

//#region constantes import
import { STUDENTS_TRAINING } from "../../Routing/PageNames";
import { STEP_PROGRESS_STATE, getTypeString } from "../StepProgress/StepProgress.constants";
//#endregion

//#region style import
import "./StepProgress.style.scss";
//#endregion

const StepProgress = ({ data, toolTips, dataTestId }) => {
  //#region hook
  const notationType = useSelector((state) => state.settingSlice.notationType);
  const translations = useSelector((state) => state.translationSlice.translations);
  const subscriptionId = useSelector((state) => state.subscriptionSlice.subscriptionId);
  const stepId = useSelector((state) => state.subscriptionSlice.currentStepId);
  const dispatch = useDispatch();
  const location = useLocation();
  //#endregion

  //#region functions
  const getToolTipTitle = () => {
    if (data.step.trainingStepType === STEP_PROGRESS_STATE.MANDATORY) {
      return getTranslations("step_progress_mandatory", translations);
    } else {
      return getTranslations("training_layout_detail_optionnal", translations);
    }
  };

  const Circle = ({ children, appearanceClass }) => {
    return (
      <Link
        id={"circle_" + data.subscriptionId + "_" + data.step.trainingStepId}
        className={`circle ${appearanceClass}`}
        onClick={moreDetails}
        data-testid={SetDataTestId(dataTestId)}
        to={
          !location.pathname.includes(STUDENTS_TRAINING.url) &&
          STUDENTS_TRAINING.url + "#result-per-machine_global_" + data.subscriptionId
        }
      >
        {children}
        {renderTooltip()}
      </Link>
    );
  };

  const renderCircle = () => {
    let apparenceClass;
    switch (data.step.stepState) {
      case STEP_PROGRESS_STATE.SUCCESS:
        return (
          <Circle appearanceClass={"circle-success"}>
            <i className="icon-check step-progress-icon" />
          </Circle>
        );
      case STEP_PROGRESS_STATE.FAIL:
        return (
          <Circle appearanceClass={"circle-fail"}>
            <i className="icon-close step-progress-icon" />
          </Circle>
        );
      case STEP_PROGRESS_STATE.CURRENT:
        apparenceClass = "circle-current";
        break;
      case STEP_PROGRESS_STATE.SKIP:
        apparenceClass = "circle-skip";
        break;
      default:
        if (data.step.totalSecondsElapsed && data.step.totalSecondsElapsed > 0) {
          apparenceClass = "circle-current";
        } else if (data.step.trainingStepType === STEP_PROGRESS_STATE.MANDATORY) {
          apparenceClass = "circle-mandatory";
        } else {
          apparenceClass = "circle-optionnal";
        }
    }
    //#endregion

    return (
      <Circle appearanceClass={apparenceClass}>
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 100 100"
        >
          <text
            className="step-progress-text"
            x="50%"
            y="50%"
            dy="0.35em"
          >
            {data.step && data.step.trainingStepOrder ? data.step.trainingStepOrder : "-"}
          </text>
        </svg>
      </Circle>
    );
  };
  const renderTooltip = () => {
    return (
      toolTips && (
        <Tooltip
          anchorSelect={"#circle_" + data.subscriptionId + "_" + data.step.trainingStepId}
          className={
            "step-progress_tooltip step-progress_tooltip--" +
            getTypeString(data.step.stepState, data.step.trainingStepType, data.step.totalSecondsElapsed)
          }
          offset={3}
          place="bottom"
          clickable
        >
          <div
            className="step-progress_tooltip_text"
            data-testid={SetDataTestId("tooltip_" + dataTestId)}
          >
            <span>
              {`${getToolTipTitle()} - `}
              <b>
                {`
              ${getTranslations("step_progress_step", translations)} ${
                  data.step.trainingStepOrder ? data.step.trainingStepOrder : "-"
                }`}
              </b>
            </span>
            <span>
              <b>{ConvertNote(data.step.averagePercent, notationType)}</b>
            </span>
          </div>
          <div className="step-progress_tooltip_text">
            <span>{getTranslations(data.step.customName ?? data.step.languageKey, translations)}</span>
            <span>
              {formatSecondToStringTime(data.step.totalSecondsElapsed, translations, FORMAT_TIMESTRING.HHMMSS)}
            </span>
          </div>

          <div className="step-progress_tooltip_more-details">
            <i className="icon-visibility" />
            <span>{getTranslations("result_detail_click_know_more", translations)}</span>
          </div>
        </Tooltip>
      )
    );
  };

  const moreDetails = () => {
    dispatch(setSubscriptionId(data.subscriptionId));
    dispatch(setCurrentStepId(data.step.trainingStepId));
    if (!location.pathname.includes(STUDENTS_TRAINING.url)) {
      dispatch(setSelectedStudent({ student: data.student }));
    }
  };

  useEffect(() => {
    if (subscriptionId && stepId) {
      document.getElementById("circle_" + subscriptionId + "_" + stepId)?.classList.add("active");
      document.getElementById("result-per-machine_details_" + subscriptionId)?.classList.add("visible");
      document.getElementById("result-per-machine_global_" + subscriptionId)?.classList.add("active");
    }
  });

  return (
    <div
      id={"step_" + data.subscriptionId + "_" + data.step.trainingStepId}
      className="step"
      data-testid={SetDataTestId("step_" + dataTestId)}
    >
      {renderCircle()}
    </div>
  );
};

StepProgress.propTypes = {
  data: PropTypes.shape({
    step: PropTypes.shape({
      trainingStepOrder: PropTypes.number,
      trainingStepId: PropTypes.number,
      trainingStepType: PropTypes.string,
      totalSecondsElapsed: PropTypes.number,
      languageKey: PropTypes.string,
      averagePercent: PropTypes.number,
      stepState: PropTypes.number,
      customName: PropTypes.string,
    }).isRequired,
    subscriptionId: PropTypes.number.isRequired,
    student: PropTypes.object,
  }).isRequired,
};

export default StepProgress;
