//#region react import
import { useSelector } from "react-redux";
//#endregion

//#region component import
import GstaToolTip from "../GstaToolTip/GstaToolTip.component";
//#endregion

//#region style import
import "./QuestionnaryChip.style.scss";
//#endregion

//#region function import
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

import { TRANSLATIONS } from "../../Constants/Translations";

const QuestionnaryChip = () => {
  const translations = useSelector((state) => state.translationSlice.translations);

  return (
    <div className={"questionnary-chip"}>
      <GstaToolTip toolTipContent={getTranslations(TRANSLATIONS.IMPROVEMENT_IDEA, translations)}>
        <i className={"icon-quick_reference"} />
      </GstaToolTip>
    </div>
  );
};

export default QuestionnaryChip;
