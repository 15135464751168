//#region react import
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
//#endregion

//#region component import
import StepProgressTimeline from "../../../Components/StepProgressTimeline/StepProgressTimeline.component";
//#endregion

//#region functions import
import useIsVisible from "../../../Helper/CustomHook/IsVisible";
import useNotationType from "../../../Helper/CustomHook/useNotationType";
import { ConvertNote } from "../../../Helper/NoteConverter";
import { FORMAT_TIMESTRING, formatSecondToStringTime } from "../../../Helper/TimeConverter";
import { getTranslations } from "../../../Helper/TranslationController";
//#endregion

//#region constants import
import { TRANSLATIONS } from "../../../Constants/Translations";
//#endregion

//#region style import
import "./TrainingResult.style.scss";
//#endregion

const TrainingResult = ({ trainingResult }) => {
  //#region hook
  const translations = useSelector((state) => state.translationSlice.translations);
  const notationType = useSelector((state) => state.settingSlice.notationType);
  const centerId = useSelector((state) => state.connexionSlice.trainer?.activeCenter?.id);
  const dispatch = useDispatch();
  const ref = useRef(null);
  const isVisible = useIsVisible(ref);
  useNotationType(dispatch, centerId, notationType);
  //#endregion

  //#region functions
  const getSpanInProgress = () => {
    let spanClass;
    let spanTranslation;
    if (trainingResult.isActive) {
      spanClass =
        trainingResult.currentStepOrder === trainingResult.stepCount
          ? "gsta-span-outlined"
          : trainingResult.totalSecondElapsed !== null
          ? "gsta-span-outlined"
          : "gsta-span-disabled";
      spanTranslation =
        trainingResult.currentStepOrder === trainingResult.stepCount
          ? getTranslations("student_detail_finished", translations)
          : trainingResult.totalSecondElapsed !== null
          ? getTranslations("student_detail_in_progress", translations)
          : getTranslations("result_detail_not_started_tag", translations);
    } else {
      spanClass = "gsta-span-outlined-invalid";
      spanTranslation = getTranslations(TRANSLATIONS.ARCHIVED_TRAINING, translations);
    }
    return <span className={spanClass}>{spanTranslation}</span>;
  };

  const getAverageSpan = () => {
    const spanClass =
      trainingResult.averagePercent === null && trainingResult.totalSecondElapsed === null
        ? "gsta-span-disabled"
        : trainingResult.averagePercent >= 50
        ? "gsta-span-valid"
        : "gsta-span-invalid";

    const spanContent =
      trainingResult.averagePercent === null && trainingResult.totalSecondElapsed === null ? (
        getTranslations(TRANSLATIONS.RESULT_DETAIL_NOT_STARTED, translations)
      ) : (
        <>
          <i className="icon-Gauge" />
          {ConvertNote(trainingResult.averagePercent, notationType)}
        </>
      );
    return <span className={spanClass}>{spanContent}</span>;
  };
  //#endregion

  return (
    <div
      ref={ref}
      className="result-per-training"
    >
      {isVisible ? (
        <div className="result-per-training_steps">
          <div className="result-per-training_steps_title">
            <h1>{getTranslations(trainingResult.customName ?? trainingResult.languageKey, translations)}</h1>
            <div className={"title-part-last"}>
              <div>
                <span className="span-title">
                  {getTranslations(TRANSLATIONS.STUDENT_DETAIL_TRAINING_MACHINE_TOTAL_TIME_ELAPSED, translations)}
                </span>
                <span className="gsta-span">
                  <i className="icon-hourglass" />
                  {formatSecondToStringTime(
                    trainingResult.totalSecondElapsed,
                    translations,
                    FORMAT_TIMESTRING.HHMM_IGNOR_SECOND
                  )}
                </span>
              </div>
              <div>
                <span className="span-title">
                  {getTranslations(TRANSLATIONS.STUDENT_DETAIL_TRAINING_AVERAGE, translations)}
                </span>
                {getAverageSpan()}
              </div>
              {getSpanInProgress()}
            </div>
          </div>
          <hr className="student-training--separator" />
          <div className="result-per-training_steps_container">
            <b>{getTranslations(TRANSLATIONS.PDF_RESULT_PER_MACHINE_PROGRESS, translations)}</b>
            <StepProgressTimeline
              data={{ steps: trainingResult.stepAverages, subscriptionId: trainingResult.subscriptionId }}
              toolTips
            />
          </div>
        </div>
      ) : (
        <div className="placholder-animation" />
      )}
    </div>
  );
};

export default TrainingResult;
